import { extendTheme } from "@mui/joy";
export const myTheme = extendTheme({
  components: {
    JoyDrawer: {
      styleOverrides: {
        backdrop: {
          backgroundColor: "transparent",
          backdropFilter: "blur(2px)",
        },
        content: {
          background: "linear-gradient(180deg, #FFFFFF 0%, #C1C1C1 100%)",
        },
      },
    },
    JoyFormControl: {
      styleOverrides: {
        root: {
          marginBlock: "15px",
        },
      },
    },
  },
});
