import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const APP_URL = `${BACKEND_URL}/users`;

// Register User
const register = async (userData: any) => {
  const response = await axios.post(`${APP_URL}/register`, userData);
  return response;
};

// Login User
const login = async (userData: any) => {
  const response = await axios.post(`${APP_URL}/login`, userData);
  return response.data;
};

// Logout User
const logout = async () => {
  const response = await axios.post(`${APP_URL}/logout`);
  return response.data.message;
};

const authService = {
  register,
  login,
  logout,
};

export default authService;
