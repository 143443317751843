import React from "react";
import { Grid, Box, Typography, Button } from "@mui/joy";
import "./LandingScreen.scss";
import { Navbar } from "../../components";
import InterviewImage from "../../assets/images/header-interview.svg";

const LandingScreen = () => {
  return (
    <Grid container lgOffset={1} lg={10}>
      <Navbar />
      <Box sx={{ height: "calc(100dvh - 60px)", width: "100%" }}>
        <div className="landing-screen">
          <main>
            <Grid container lg={12} sm={12}>
              <Grid lg={6} sm={12}>
                <Typography className="header-text">
                  Get <span className="header-text-light">Your</span> <br></br>{" "}
                  Dream Job <br></br>{" "}
                  <span className="header-text-light">With</span> <br></br>{" "}
                  <span className="header-text-logo">Careers</span>ForMe
                </Typography>
                <Button
                  className="get-started-btn"
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    window.open(
                      "https://chat.whatsapp.com/Bhe9IdSZID74nWsVEHGFfg"
                    );
                  }}
                >
                  Let's get started
                </Button>
              </Grid>
              <Grid
                lgOffset={1}
                lg={5}
                sm={12}
                alignItems={"right"}
                className="header-image"
              >
                <img src={InterviewImage} width={500} height={375} />
              </Grid>
              <Typography level="body-lg" className="click-here">
                Connect us on Whatsapp Group to get job updates
                <span
                  onClick={() => {
                    window.open(
                      "https://chat.whatsapp.com/Bhe9IdSZID74nWsVEHGFfg"
                    );
                  }}
                >
                  {" "}
                  Click here
                </span>
              </Typography>
            </Grid>
          </main>
        </div>
      </Box>
      {/* <Footer /> */}
    </Grid>
  );
};

export default LandingScreen;
