import React from "react";
import "./ApplicationLayout.scss";
import RecruiterRoute from "../../routes/RecruiterRoute";

const RecruiterApplicationLayout = () => {
  return (
    <div>
      <RecruiterRoute />
    </div>
  );
};

export default RecruiterApplicationLayout;
