import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
/**
 * Renders a protected route.
 * @returns JSX.Element - The protected route component.
 */

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(
    (state: any) => localStorage.getItem("isLoggedIn") || state.auth.isLoggedIn
  );

  React.useEffect(() => {
    if (!isLoggedIn && window.location.pathname !== "/") {
      navigate("/login");
    }
  }, [navigate, isLoggedIn]);

  return <Outlet />;
};

export default ProtectedRoute;
