import React from "react";
import "./ScreenLoader.scss";
import { useSelector } from "react-redux";
const ScreenLoader = () => {
  const { isLoading } = useSelector((state: any) => state.auth);
  if (!isLoading) {
    return <></>;
  }
  return (
    <div className="screen-loader">
      <div className="screen-loader-ring"></div>
      <span>Loading...</span>
    </div>
  );
};

export default ScreenLoader;
