import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { getApp } from "./utils/Routing";
import { CssBaseline, CssVarsProvider } from "@mui/joy";
import { myTheme } from "./themes/myTheme";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import { ScreenLoader } from "./components";

axios.defaults.withCredentials = true;
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.NODE_ENV !== "development") console.log = () => {};

const customTheme = myTheme;
const CurrentApp = getApp();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <CssVarsProvider theme={customTheme}>
          <CssBaseline />
          <ToastContainer />
          <ScreenLoader />
          <CurrentApp />
        </CssVarsProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
