import React from "react";
import "./Navbar.scss";
import Logo from "../../assets/images/newLogo.svg";
import { Button, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
// import { LoginForm } from "../LoginForm";
// import { UserRegistrationForm } from "../RegistrationForm";
// import { Link } from "react-router-dom";

interface NavbarProps {}
const Navbar: React.FC<NavbarProps> = () => {
  // const [loginDrawerOpen, setLoginDrawerOpen] = React.useState<boolean>(false);
  // const [registerDrawerOpen, setRegisterDrawerOpen] =
  //   React.useState<boolean>(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  return (
    <nav className="nav">
      <div className="nav-logo">
        <img src={Logo} alt="logo" />
      </div>
      <div className="nav-menu-list">
        <Typography
          onClick={() => {
            setModalOpen(true);
          }}
          className="nav-menu-item"
        >
          Home
        </Typography>
        <Typography
          onClick={() => {
            setModalOpen(true);
          }}
          className="nav-menu-item"
        >
          Jobs
        </Typography>
        <Typography
          onClick={() => {
            setModalOpen(true);
          }}
          className="nav-menu-item"
        >
          Blogs
        </Typography>
        <Typography
          onClick={() => {
            setModalOpen(true);
          }}
          className="nav-menu-item"
        >
          Contact Us
        </Typography>
      </div>
      <div className="nav-menu">
        <Button
          onClick={() => {
            setModalOpen(true);
          }}
          variant="outlined"
        >
          Register
        </Button>
        <Button
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Login
        </Button>
      </div>
      {/* <LoginForm
        setDrawerOpen={setLoginDrawerOpen}
        drawerOpen={loginDrawerOpen}
        user="user"
      />
      <UserRegistrationForm
        setDrawerOpen={setRegisterDrawerOpen}
        drawerOpen={registerDrawerOpen}
      /> */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        className="modal-coming-soon"
      >
        <ModalDialog>
          <ModalClose />
          <Typography
            component="h2"
            id="modal-title"
            level="h2"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Coming soon
          </Typography>
          <Typography level="body-lg" className="click-here">
            We are currently active on Whatsapp. Join our Whatsapp Group to get
            job updates
            <br></br>
            <span
              onClick={() => {
                window.open("https://chat.whatsapp.com/Bhe9IdSZID74nWsVEHGFfg");
              }}
            >
              Click here
            </span>
          </Typography>
        </ModalDialog>
      </Modal>
    </nav>
  );
};

export default Navbar;
