import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend"; // Import HttpBackend from 'i18next-http-backend'

i18n
  .use(HttpBackend) // Use HttpBackend for loading translations over HTTP
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en", // default language
    debug: true, // enable debug mode
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      whitelist: ["en", "fr"], // Specify the languages you want to allow
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json", // path to translations on the server
    },
  });

export default i18n;
