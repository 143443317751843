import {
  UserApplicationLayout,
  RecruiterApplicationLayout,
} from "../components";

export const APPS = [
  {
    subdomain: "www",
    app: UserApplicationLayout,
    main: true,
  },
  {
    subdomain: "recruiter",
    app: RecruiterApplicationLayout,
    main: false,
  },
];

const getSubdomain = (location: string) => {
  const locationParts: string[] = location.split(".");
  let sliceTill = -2;
  // for localhost
  const isLocalHost = locationParts.slice(-1)[0] === "localhost";
  if (isLocalHost) sliceTill = -1;

  return locationParts.slice(0, sliceTill).join(".");
};
export const getApp = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const main = APPS.find((app) => app.main);
  if (!main) throw new Error("No main router found");
  if (subdomain === "") return main.app;
  const app = APPS.find((app) => app.subdomain === subdomain);
  if (!app) return main.app;
  return app.app;
};
